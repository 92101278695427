import React, { useEffect, useState } from "react";
import "./ThankPageNew.css";
import LogoSapirThankPage from "../images/landingPage_March_OPEN-15.png";
import LogoSapirThankPageMobile from "../images/landingPage_March_OPEN-40.png";
import SapirImage from "../images/landingPage_March_OPEN-16.png";

function ThankPageNew() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const redirectToSapirWebsite = () => {
    window.location.href = "https://www.sapir.ac.il/";
  };

  return (
    <div className="thank-page-div">
      {isMobile && (
        <img
          className="logo-sapir-thank-page-mobile"
          src={LogoSapirThankPageMobile}
        />
      )}
      <img className="image-sapir-thank-page" src={SapirImage} />
      <div className="text-thank-page">
        {!isMobile && (
          <img className="logo-sapir-thank-page" src={LogoSapirThankPage} />
        )}
        <div className="lines-thank-page">
          <h1 className="almoni-ultra-bold-title-thank-page">
            תודה שפנית אלינו!{" "}
          </h1>
          <h1 className="almoni-ultra-bold-title-thank-page">
            פנייתך התקבלה בהצלחה
          </h1>
          <h1 className="almoni-light-title-thank-page">יועצי הלימודים שלנו</h1>
          <h1 className="almoni-light-title-thank-page">יחזרו אליך בקרוב</h1>
          <button
            className="thank-page-button"
            onClick={redirectToSapirWebsite}
          >
            לאתר שלנו
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThankPageNew;
