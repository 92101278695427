import React, { useState, useEffect } from "react";
import "../App.css";
import "./HomePage.css";
import PoalimImage from "../images/landingPage_July-20.png";
import PoalimImageMobile from "../images/landingPage_July-26.png";
import Sapir26to7 from "../images/landingPage_July-22.png";
import Sapir26to7Mobile from "../images/landingPage_July-25.png";
import SapirOpportunity from "../components/SapirOpportunity";
import Logos from "../images/landingPage_July-23.png";
import TuitionFinancing from "../images/landingPage_July-24.png";

function HomePage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="background">
      {!isMobile && (
        <img
          src={Logos}
          alt="לוגו מכללת ספיר לוגו בנק הפועלים"
          className="logo"
        />
      )}
      <div className="text-container">
        {isMobile && (
          <img
            src={Logos}
            alt="לוגו מכללת ספיר לוגו בנק הפועלים"
            className="logo-mobile"
          />
        )}
        <img
          src={isMobile ? PoalimImageMobile : PoalimImage}
          alt="פועלים לעתיד מנהיגות ויזמות בספיר"
          className="right-image"
        />
        <div className="text-content">
          <SapirOpportunity />
        </div>
        {isMobile && (
          <div className="details-image-">
            <img
              src={TuitionFinancing}
              alt="מימון שכר לימוד מלא* לשנה א בתשפה ההשתתפות במיזה תזכה במימון שכר לימוד"
              className=""
            />
          </div>
        )}
        <img
          src={isMobile ? Sapir26to7Mobile : Sapir26to7}
          alt="יום חשיפה בספיר 26.7"
          className="left-image"
        />
      </div>
      <br></br>
      <a href="https://www.sapir.ac.il/node/268">הצהרת נגישות</a>
    </div>
  );
}

export default HomePage;
