import React, { useState, useEffect } from "react";
import "../App.css";
import "./SapirOpportunity.css";
import TuitionFinancing from "../images/landingPage_July-24.png";
import Line from "../images/landingPage_July-27.png";
import degreeOptions from "../data/degreeOptions";

function SapirOpportunity() {
  const [selectedDegreeOption, setSelectedDegreeOption] = useState("");
  const [selectedDegree] = useState("bachelor");
  const [isMobile, setIsMobile] = useState(false);
  const [isButtonDisabledLast, setIsButtonDisabledLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    option: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDegreeOptionChange = (event) => {
    setSelectedDegreeOption(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const fullNameInput = document.getElementById("fullName");
    const emailInput = document.getElementById("mail");
    const phoneInput = document.getElementById("phone");
    // const degree = document.getElementById("degree");
    const degreeInput = document.getElementById("degreeOption");

    if (
      !fullNameInput.checkValidity() ||
      !emailInput.checkValidity() ||
      !phoneInput.checkValidity()
    ) {
      alert("Please fill in all the required fields.");
      return;
    } else {
      setIsButtonDisabledLast(true);
      setIsLoading(true);

      const fullName = fullNameInput.value;
      const [firstName, lastName] = fullName.split(" ");
      const email = emailInput.value;
      const phone = phoneInput.value;
      const department = degreeInput.value;
      const selectedOption = degreeInput.options[degreeInput.selectedIndex];
      const departmentLabel = selectedOption.textContent;
      const username = process.env.REACT_APP_USERNAME;
      const password = process.env.REACT_APP_PASSWORD;
      const sourceId = process.env.REACT_APP_SOURCE_ID;
      const queryParams = new URLSearchParams(window.location.search);
      const utmSource = queryParams.get("utm_source");
      const utmMedium = queryParams.get("utm_medium");
      const utmCampaign = queryParams.get("utm_campaign");

      const jsonData = {
        data: {
          FIRSTNAME: firstName,
          LASTNAME: lastName ? lastName : ".",
          PHONE: phone,
          EMAIL: email,
          DEPARTMENT: department,
          // INTERESTED: interested,
          DEPARTMENT_NAME: departmentLabel,
          username: username,
          password: password,
          SOURCE_ID: sourceId,
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaign: utmCampaign,
        },
      };
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        jsonData,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://openday.sapir.ac.il/api/process_request", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setIsLoading(false);
          window.location.href += "thankPage";
        })

        .catch(
          (error) => {
            console.error(error);
            setIsLoading(false);
          }
          // (window.location.href += "thankPage")
        );
    }
  };

  return (
    <div className="sapir-container">
      <div className="details-container">
        <div className="details-text">
          <h1>תואר ראשון בספיר!</h1>
          <p>
            ההזדמנות שלך לתרום לעוטף ישראל, לפתח כישורי מנהיגות ויזמות וליהנות
            ממלגת שכר לימוד*!
          </p>
          {isMobile && <img src={Line} alt="Line" className="line" />}
        </div>

        {!isMobile && (
          <div className="details-image">
            <img
              src={TuitionFinancing}
              alt="מימון שכר לימוד מלא* לשנה א בתשפה ההשתתפות במיזה תזכה במימון שכר לימוד"
              className=""
            />
          </div>
        )}
      </div>

      <br></br>
      <h2 className="red-title">לתיאום ייעוץ אישי:</h2>
      <form onSubmit={handleSubmit}>
        <input
          id="fullName"
          type="text"
          name="fullName"
          placeholder="השם שלי *"
          value={formData.fullName}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <input
          id="phone"
          type="tel"
          name="phone"
          placeholder="* הנייד שלי"
          value={formData.phone}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <input
          id="mail"
          type="email"
          name="email"
          placeholder="המייל שלי *"
          value={formData.email}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <select
          id="degreeOption"
          onChange={handleDegreeOptionChange}
          value={selectedDegreeOption}
          aria-label="my field of interest"
          required
          aria-required="true"
        >
          <option value="">תחום העניין שלי *</option>
          {degreeOptions[selectedDegree].map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <button type="submit" disabled={isButtonDisabledLast}>
          {isLoading ? <div className="loader"></div> : "שליחה"}
        </button>
      </form>
    </div>
  );
}

export default SapirOpportunity;
